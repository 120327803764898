
import { Component, Prop, Vue } from 'vue-property-decorator'
import { updateReservationPurchaseOrder } from '@/services/reservations'
import {
  isNotEmpty,
  isRequired,
} from '@/utils/validators'
import { EventBus } from '@/utils/event-bus'
import { capitalize } from '@/utils/string'

@Component
export default class PurchaseOrderSidebar extends Vue {
  @Prop({ required: false, default: null }) readonly purchaseOrderNumber: string | null
  @Prop({ required: false, default: null }) readonly purchaseOrderStatus: string | null
  @Prop({ required: true}) readonly purchaseOrderStatuses!: string[] | []
  @Prop({ required: true}) readonly reservationExternalId!: string

  poNumber: string | null = null
  poStatus: string | null = null
  poStatuses: string[] | [] = []
  loading: boolean = false
  isRequired = isRequired
  isNotEmpty = isNotEmpty
  capilalize = capitalize

  mounted() {
    this.poNumber = this.purchaseOrderNumber
    this.poStatus = this.purchaseOrderStatus
    this.poStatuses = this.purchaseOrderStatuses
  }

  get formattedStatuses() {
    return this.purchaseOrderStatuses.map(status => ({
        value: status,
        displayName: capitalize(status)
      }))
  }

  async save() {
    this.loading = true
    let isValid = (this.$refs['form'] as any).validate()
    if (!isValid) {
        this.loading = false
        return
    }

    try {
      await updateReservationPurchaseOrder(this.reservationExternalId, {
        purchaseOrderNumber: this.poNumber,
        purchaseOrderStatus: this.poStatus
      })

      EventBus.$emit('refresh-detail')
      EventBus.$emit('refresh-query-request')

      this.$store.dispatch('app/closeDialog')
    } catch (error) {
      console.error("Failed to update purchase order:", error)
      this.$store.dispatch(
        'app/showAlert',
        { message: 'Failed to update purchase order.', type: 'error' },
        { root: true }
      )
    } finally {
      this.loading = false
    }
  }
}
