var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"purchase-order-edit",attrs:{"id":"purchase-order-edit"}},[_c('v-form',{ref:"form",staticClass:"purchase-order-edit--content"},[_c('CRSelect',{attrs:{"id":`purchase-order-status-selector`,"items":_vm.formattedStatuses,"rules":[_vm.isRequired(true, _vm.isNotEmpty, {
            req: 'PO Status is Required',
            error: 'PO Status is Required',
          })],"item-text":"displayName","item-value":"value","label":"PO Status","placeholder":"Outstanding"},model:{value:(_vm.poStatus),callback:function ($$v) {_vm.poStatus=$$v},expression:"poStatus"}}),_c('CRInput',{attrs:{"placeholder":"PO #","label":"PO #","rules":[_vm.isRequired(true, _vm.isNotEmpty, {
            req: 'PO # is Required',
            error: 'PO # is Required',
          })]},model:{value:(_vm.poNumber),callback:function ($$v) {_vm.poNumber=$$v},expression:"poNumber"}})],1),_c('div',{staticClass:"cr-sidebar-dialog--btn-spacer"}),_c('div',{staticClass:"cr-sidebar-dialog--action-btn",on:{"click":_vm.save}},[(!_vm.loading)?_c('span',[_vm._v("Save")]):_c('CRProgressCircular',{attrs:{"size":27}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }